import React from "react";
import {
  Portal,
  Overlay,
  Title,
  Content,
  DialogClose,
} from "@radix-ui/react-dialog";
import BookingForm from "./BookingForm";
import { IoIosClose } from "react-icons/io";

const PopUpWithBookingForm = () => {
  return (
    <Portal>
      <Overlay className=" bg-[rgba(0,0,0,.8)] z-20 DialogOverlay   fixed inset-0" />
      <Content
        onCloseAutoFocus={(e) => e.preventDefault()}
        className="data-[state=open]: overflow-y-auto DialogContent delay-1000 max-h-full  duration-500 fixed top-[50%] left-[50%] z-20   max-md:w-[95%] md:max-w-[60%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
      >
        <DialogClose asChild>
          <button className="ml-auto w-fit block">
            <IoIosClose size={40} className="text-primary" />
          </button>
        </DialogClose>
        <Title className=" text-3xl py-3 font-medium text-primary">
          Reach out now!
        </Title>
        <BookingForm />
      </Content>
    </Portal>
  );
};

export default PopUpWithBookingForm;
