function BecomeAffiliateSection() {
  return (
    <section className="bg-gray-100 py-10">
      <div className="container mx-auto">
        <div className="max-md:flex-col max-md:items-center max-md:text-center max-md:gap-5 flex">
          <div className="flex-1">
            <div className="text-primary capitalize">
              <h1 className="text-4xl underline font-bold">
                affiliate Program
              </h1>
              <p className="text-4xl">Refer Clients,Earn Cash</p>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="md:flex flex-col gap-3 "
          >
            <div className="flex">
              <input
                type="email"
                required
                className="min-w-64"
                placeholder="Email"
              />
              <button className=" bg-primary px-6  text-white p-2.5">
                Sign up
              </button>
            </div>
            <div className="flex gap-2 items-center text-lg">
              <input id="affiliateAgree" type="checkbox" />
              <label htmlFor="affiliateAgree" className="text-gray-500">
                Yes, i Want to become an affiliate{" "}
              </label>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default BecomeAffiliateSection;
